import React from 'react'
import { css } from '@emotion/react'
import AppColor from '~/libs/AppColor'
import { Link, navigate } from 'gatsby'
import AppSize from '~/libs/AppSize'

const StaffList: React.FC = () => {
  return (
    <div css={listRootCss}>
      <div css={titleCss}>
        <div css={titleMainCss}>Team Tobu Trip</div>
        <div css={titleSubCss}>WEB SITE</div>
      </div>
      <div css={itemCss}>
        <div css={roleNameCss}>Produce</div>
        <div css={staffNameCss}>シマヲ</div>
      </div>
      <div css={itemCss}>
        <div css={roleNameCss}>Design</div>
        <div css={staffNameCss}>
          岡裕希<span className="small">（alaska）</span>
        </div>
      </div>
      <div css={itemCss}>
        <div css={roleNameCss}>Sound Design</div>
        <div css={staffNameCss}>
          笠松広司<span className="small">（Digitalcircus）</span>
        </div>
      </div>
      <div css={itemCss}>
        <div css={roleNameCss}>Logo × Character Design</div>
        <div css={staffNameCss}>
          くっきー！<span className="small">（野性爆弾）</span>
        </div>
      </div>
      <div css={itemCss}>
        <div css={roleNameCss}>Illustrator</div>
        <div css={staffNameCss}>☆shine</div>
      </div>
      <div css={itemCss}>
        <div css={thanksCss}>Special Thanks</div>
      </div>
      <div css={itemCss}>
        <div css={staffNameCss}>辻稔</div>
        <div css={staffNameCss}>家永洋</div>
      </div>
      <div css={itemCss}>
        <div css={staffNameCss}>マッコイ斎藤</div>
        <div css={staffNameCss}>内田吉啓</div>
      </div>
      <div css={itemCss}>
        <div css={staffNameCss}>東中川遼太</div>
      </div>
      <div css={lastStaffCss}>
        <div css={roleNameCss}>Direction</div>
        <div css={staffNameCss}>片岡飛鳥</div>
      </div>
    </div>
  )
}
export default StaffList

const listRootCss = css`
  display: flex;
  align-items: center;
  color: #fff;
  white-space: nowrap;
`

const titleCss = css`
  font-size: 16px;
  margin-right: 40px;
  text-align: center;
`
const titleMainCss = css`
  font-size: 17px;
  font-weight: bold;
`
const titleSubCss = css`
  font-size: 16px;
`

const itemCss = css`
  margin: 0 40px;
`
const roleNameCss = css`
  font-size: 12px;
`
const staffNameCss = css`
  font-size: 20px;
  .small {
    font-size: 15px;
  }
`
const thanksCss = css`
  font-weight: bold;
  font-size: 16px;
`
const lastStaffCss = css`
  margin-left: 180px;
`
